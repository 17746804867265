import {AxiosResponse} from 'axios';

import makeAdminRequest from '../../adminRequest';

import MessageResponse from '../../../types/api/MessageResponse';
import IPrizePoolControllers, {
  ILastPrizePool,
  IPrizePoolControllerEdit
} from '../../../types/api/Banking/PrizePoolController';
import ItemTypes from '../../../types/api/Admin/ItemTypes';

export async function getPrizePoolControllers(): Promise<AxiosResponse<IPrizePoolControllers[]>> {
  return makeAdminRequest('GET', '/api/banking/admin/prize-pool-controllers');
}

export async function setPrizePoolController({
  prizePoolType,
  prizePoolPeriodHours,
  prizePoolVestedAmount,
  prizePoolPurchasesAmount,
  prizePoolThresholdPercents,
  prizePoolsEnabled
}: IPrizePoolControllerEdit): Promise<AxiosResponse<MessageResponse>> {
  return makeAdminRequest('POST', '/api/banking/admin/prize-pool-controller', {
    prizePoolType, prizePoolPeriodHours, prizePoolVestedAmount,
    prizePoolPurchasesAmount: prizePoolType === 0 ? undefined : prizePoolPurchasesAmount,
    prizePoolThresholdPercents: prizePoolType === 0 ? prizePoolThresholdPercents : undefined,
    prizePoolsEnabled
  });
}

export async function getLastPrizePools(): Promise<AxiosResponse<ILastPrizePool[]>> {
  return makeAdminRequest('GET', '/api/banking/admin/prize-pool-controller/last-pools');
}

export async function getPrizePoolTypes(): Promise<AxiosResponse<ItemTypes[]>> {
  return makeAdminRequest('GET', '/api/banking/admin/prize-pool-controller/types');
}

export async function updateAmount(poolId: string, action: string, amount: number) {
  return makeAdminRequest('POST', `/api/banking/admin/prize-pool-controller/prize-pools/${poolId}/amount/${action}`, {
    amount
  });
}

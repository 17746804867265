import {AxiosResponse} from 'axios';

import makeAdminRequest from '../../adminRequest';

import IBadgesSettings, {ISlotSettings} from '../../../types/api/Admin/Badges/Badges';

export async function getBadgesSettings(): Promise<AxiosResponse<IBadgesSettings[]>> {
  return makeAdminRequest('GET', '/api/banking/admin/pure-utilities/badges-settings');
}

export async function getSlotsSettings(): Promise<AxiosResponse<ISlotSettings[]>> {
  return makeAdminRequest('GET', '/api/banking/admin/pure-utilities/badges-slots-settings');
}

export async function setBadgesSettings({
  rarity, maxCharges, matchMinutesPerCharge, chargeCost, chargeSpeedupCost, chargeTimeSeconds,
  craftCost, previousBadgesToCraft, craftTimeSeconds, rewardMultiplier, rewardMultiplierDecimals
}: IBadgesSettings) {
  return makeAdminRequest('POST', '/api/banking/admin/pure-utilities/badges-settings', {
    rarity, maxCharges, matchMinutesPerCharge, chargeCost, chargeSpeedupCost, chargeTimeSeconds,
    craftCost, previousBadgesToCraft, craftTimeSeconds, rewardMultiplier, rewardMultiplierDecimals
  });
}

export async function setSlotSettings({
  slot, bonus, bonusDecimals, cost
}: ISlotSettings) {
  return makeAdminRequest('POST', '/api/banking/admin/pure-utilities/badges-slots-settings', {
    slot, bonus, bonusDecimals, cost
  });
}
